<template>
  <div class="home" :style="isPopup?'height:100vh;overflow:hidden':''">
    <div class="homeTopHeader normalBox">
      <div class="clearfix headtop">
        <router-link class="fl" to="/">
          <img src="~img/home/logo.png" alt="" />
        </router-link>
        <div class="fr">
          <router-link to="/collect">
            <img src="~img/home/book.png" alt="" />
          </router-link>
          <routerLink to="/center">
            <img src="~img/home/icon_my@2x.png" alt="" />
          </routerLink>
        </div>
      </div>
      <div class="historyread clearfix" v-if="firstHistory !== null">
        <div class="fl ellipsis" style="width:80%" v-if="firstHistory">
          上次讀到：《{{ firstHistory.bookName }}》
          {{ firstHistory.chapterName }}
        </div>
        <router-link
          class="fr"
          :to="'/book/' + firstHistory.bookId + '/' + firstHistory.chapterId"
        >
          接着讀 ></router-link
        >
      </div>
    </div>
    <!-- 輪播 -->
    <mt-swipe :auto="3000">
      <mt-swipe-item v-for="item in homeSwiper.content" :key="item.id">
        <router-link :to="'/book/' + item.bookId" v-if="item.book">
          <img :src="item.imgUrl" alt="" v-if="item.book" />
        </router-link>
        <a :href="item.url" v-else>
          <img :src="item.imgUrl" alt="" />
        </a>
      </mt-swipe-item>
    </mt-swipe>
    <!-- 搜索 -->
    <div class="searchbox clearfix" @click="goSearch">
      <a href="javaScript:" class="fl"
        ><img src="~img/home/icon_search@2x.png" alt=""
      /></a>
      <input type="text" class="fl" placeholder="搜索書/作者" />
    </div>
    <!-- 導航欄 -->
    <div class="navbox">
      <ul>
        <li v-for="item in navlist" :key="item.name">
          <router-link :to="item.url">
            <img :src="item.img" alt="" />
            <p>{{ item.name }}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="line"></div>
    <!-- 推薦位 -->
    <ThreeBigShelf
      :shelf="dashenShelf1"
      :booklist="dashenShelf2"
      :title="dashenShelf.name"
      :shelfid="dashenShelf.id"
    ></ThreeBigShelf>
    <!-- 廣告位 -->
    <router-link  v-for="i in guanggaoShelf.content" :to="'/book/' + i.bookId">
      <img
        :src="i.imgUrl"
        alt=""
        class="imgRecommend"
      />
    </router-link>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="bianShelf.content"
      :title="bianShelf.name"
      :shelfid="bianShelf.id"
    ></eightShelf>
    <!-- 熱門標籤 -->
    <!-- <div class="ShelfRecommend normalBox">
      <h3 class="clearfix">
        <div class="shelfName fl clearfix">
          <span class="left-line fl"></span>
          <p class="fl">{{ Atags.name }}</p>
        </div>
        <routerLink to="/" class="rightMore fr clearfix">
          <span class="fl">更多</span>
          <img class="fl" src="~img/home/Path7@2x.png" alt="" />
        </routerLink>
      </h3>
      <div class="bscroll" ref="slider">
        <ul
          class="item content clearfix"
          v-if="tags"
          :style="'width:' + (137 / 75) * tags.length + 'rem'"
        >
          <li v-for="item in Atags.content" :key="item.name" class="fl">
            <img src="~img/tag@2x.png" alt="" />
            <span :style="randomRgb()">{{ item.name }}</span>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- 推薦位3 -->
    <threeShelf
      :shelf="hotShelf.content"
      :title="hotShelf.name"
      :shelfid="hotShelf.id"
    ></threeShelf>
    <!-- 推薦位 -->
    <ThreeBigShelf
      :shelf="pangone"
      :booklist="pangtwo"
      :title="pangShelf.name"
      :shelfid="pangShelf.id"
    ></ThreeBigShelf>
    <!-- 8本推薦位 -->
    <eightShelf
      :shelf="reShelf.content"
      :title="reShelf.name"
      :shelfid="reShelf.id"
    ></eightShelf>
    <!--弹出框-->
    <popup-home @closeTG="closeTG" v-if="isPopup" :imgType="imgType" :phoneType="phoneType"></popup-home>
  </div>
</template>

<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '818816462586527');
  fbq('track', 'PageView');
import { homeshelf, booktitle } from "apiurl/book";
import { lastHistory } from "apiurl/user";
import { getToken, getHomeScreen } from "utils/storage";
/* 引入better-scroll */
import BScroll from "better-scroll";
export default {
  name: "home",
  data() {
    return {
      navlist: [
        {
          name: "男頻",
          img: require("../static/home/nav_(4).png"),
          url: "/boy"
        },
        {
          name: "女頻",
          img: require("../static/home/nav_(1).png"),
          url: "/girl"
        },
        {
          name: "最近閱讀",
          img: require("../static/home/nav_(5).png"),
          url: "/recentlyRead"
        },
        {
          name: "充值",
          img: require("../static/account/payimg.png"),
          url: "/toup"
        },
        {
          name: "書庫",
          img: require("../static/home/nav_(3).png"),
          url: "/sort"
        }
      ],
      tags: [],
      firstHistory: null,
      allShelfList: [],
      homeSwiper: {},
      dashenShelf: {},
      dashenShelf1: [],
      dashenShelf2: [],
      guanggaoShelf: [],
      pangShelf: {},
      bianShelf: {},
      Atags: {},
      reShelf: {},
      pangone: [],
      pangtwo: [],
      hotShelf: {},
      isPopup: false,
      imgType: '',
      phoneType: '',
    };
  },
  activated() {
    // this.tuiguangFun()
  },
  created() {
    this.$nextTick(() => {
      this.getIdShelf();
      this.tuiguangFun()
    });
  },
  methods: {
    // 歷史記錄
    async gethistory() {
      let res = await lastHistory();
      if (res.success) {
        this.firstHistory = res.data;
      } else {
        this.firstHistory = null;
      }
    },
    // 歷史記錄
    async gebooktitle() {
      let res = await booktitle({bookId: this.$route.query.bid, chapterId: this.$route.query.cid});
      if (res.success) {
        this.firstHistory = res.data;
      } else {
        this.firstHistory = null;
      }
    },
    // 隨即色
    randomRgb() {
      let R = Math.floor(Math.random() * 255);
      let G = Math.floor(Math.random() * 255);
      let B = Math.floor(Math.random() * 255);
      return {
        color: "rgb(" + R + "," + G + "," + B + ")"
      };
    },
    getIdShelf(pageId) {
      homeshelf({ pageId }).then(res => {
        if (res.success) {
          this.allShelfList = res.data.shelves;
          this.allShelfList.forEach(item => {
            if (item.id == "yuanshi_Wap_Swipers") {
              this.homeSwiper = item;
            } else if (item.id == "yuanshi_selection_popular") {
              this.dashenShelf = item;
              this.dashenShelf1 = item.content.splice(0, 3);
              this.dashenShelf2 = item.content;
            } else if (item.id == "yuanshi_selection_dashen") {
              this.pangShelf = item;
              this.pangone = item.content.splice(0, 3);
              this.pangtwo = item.content;
            } else if (item.id == "yuanshi_selection_editor_recommend") {
              this.bianShelf = item;
            } else if (item.id == "yuanshi_selection_biaoqian") {
              this.Atags = item;
              this.tags = item.content;
            } else if (item.id == "yuanshi_wap_boutique") {
              this.reShelf = item;
            } else if (item.id == "yuanshi_wap_hot") {
              this.hotShelf = item;
              this.hotShelf.content.forEach(item => {
                item.book.introduce =
                  item.book.introduce.substring(0, 30) + "...";
              });
            } else if (item.id == "yuanshi_wap_banner") {
              this.guanggaoShelf = item
            }
          });
        }
      });
    },
    goSearch() {
      this.$router.push("/search");
    },
    // 推广相关
    tuiguangFun () {
      if (this.$route.query.cid && this.$route.query.bid) {
        var standalone = window.navigator.standalone // 检测iOS WebApp是否运行在全屏模式
        var ua = window.navigator.userAgent.toLowerCase()
        var safari = /safari/.test(ua);
        var ios = /iphone|ipod|ipad/.test(ua);
        if (ua.indexOf("FBAV") > -1) { // 内置
          this.isPopup = true
          this.imgType = 'tiaozhuan'
          if (ios) {
            this.phoneType = 'IOS'
          } else {
            this.phoneType = 'android'
          }
        } else { //非内置
          if (!getHomeScreen())  {
            this.isPopup = true
            this.imgType = 'baocun'
            if (ios) {
              this.phoneType = "IOS"
            }else{
              this.phoneType = "android"
            }
          } else {
            this.isPopup = false
          }
          this.gebooktitle()
        }
      } else {
        // if (!getHomeScreen()) {
        //   this.imgType = 'baocun'
        //   this.isPopup = true
        //   if (ios) {
        //     this.phoneType = "IOS"
        //   }else{
        //     this.phoneType = "android"
        //   }
        // } else {
        //   this.isPopup = false
        //   this.imgType = ''
        // }
        if (getToken()) {
          this.gethistory();
        } else {
          this.firstHistory = null;
        }
      }
    },
    closeTG (data) {
      this.isPopup = false
    },
    isSafaria () {
      var ua = navigator.userAgent.toLowerCase();

    }
  }
};
</script>
<!-- Meta Pixel Code -->
<noscript><img height="1" width="1" style="display:none"
  src="https://www.facebook.com/tr?id=1068936893787069&ev=PageView&noscript=1"
  /></noscript>
<!-- End Meta Pixel Code -->
<style lang="stylus" scoped>
@import '~@/styles/cominxin.styl'
.home
    width 100vw
    overflow hidden
.homeTopHeader
    margin-top 14px
    box-shadow 0px 4px 6px 0px rgba(0, 0, 0, 0.05)
    padding-bottom 8px
    margin-bottom 29px
    .headtop
        .fl
            img
                width 180px
                height 64px
        .fr
            img
                width 36px
                height 36px
                margin-top 14px
                margin-left 34px
    .historyread
        color #2088F2
        margin-top 16px
        font-size 24px
        height 33px
        line-height 33px
        a
            color #2088F2
            font-size 24px
            height 33px
            line-height 33px
.searchbox
    height 60px
    margin 0 48px 24px
    background rgba(249, 249, 249, 1)
    border-radius 30px
    border 2px solid rgba(219, 219, 219, 1)
    input
        background none
        placeMain(#D7D7D7, 24px, 0px)
        height 100%
    img
        width 28px
        height 28px
        margin-left 230px
        margin-right 20px
        padding-top 15px
.navbox
    margin 30px 0
    /*margin 30px 60px 30px*/
    ul
      display flex
      -webkit-display flex
    li
        flex 1
        -webkit-flex 1
        /*margin-right 70px*/
        &:last-child
            margin-right 0
        a
            text-align center
            display block
            img
                width 56px
                height 56px
        p
            text-align center
            color #323232
            font-size 26px
/* 標籤 */
.bscroll
    width 100%
    overflow hidden
    margin-bottom 40px
    ul
        li
            width 104px
            height 59px
            position relative
            margin-right 33px
            line-height 59px
            height 59px
            img
                position absolute
                width 104px
                height 59px
                top 0
                left 0
                z-index -1
            span
                display inline-block
                margin-left 36px
                font-size:24px;
                font-weight 500
</style>
